export const COMMON_LOGIN_BAR_STYLE_PROPERTIES = {
  'alpha-bg': '0',
  'alpha-brd': '1',
  'alpha-brd-dd': '0.2',
  'badge-bg': '#E21C21',
  'badge-txt': 'color_11',
  bg: 'transparent',
  'bg-dd': 'color_11',
  'boxShadowToggleOn-shd': 'false',
  'boxShadowToggleOn-shd-dd': 'false',
  brd: 'color_15',
  'brd-dd': 'color_15',
  brw: '0',
  'brw-dd': '1px',
  fillcolor: 'color_18',
  fnt: 'font_8',
  'fnt-size-dd': '15px',
  rd: '0px',
  'rd-dd': '0px',
  shd: '0 0 0 transparent',
  'shd-dd': '0 0 0 transparent',
  txt: 'color_18',
  'txt-dd': 'color_15',
  txth: 'color_17',
  'txth-dd': 'color_14',
  'txt-slct-dd': 'color_18',
  avatarAndArrowStrokeWidth: '0',
  avatarCornerRadius: '100px',
};

export const COMMON_LOGIN_BAR_COMP_DATA = {
  type: 'LoginSocialBar',
  loggedInMember: 'avatarOnly',
  loggedOutText: 'Log In',
  showLoggedInText: false,
  loggedInText: 'Hello',
  logOutText: 'Log Out',
  menuItemsRef: null,
  iconItemsRef: null,
  language: 'en',
  role: 'members_login',
};

export const COMMON_LOGIN_COMP_CONNECTIONS = {
  type: 'ConnectionList',
  items: [
    {
      type: 'ConnectionItem',
      controllerId: 'login-bar-controller-id',
      isPrimary: true,
      config: '{}',
      role: 'members-login-bar',
    },
  ],
};

export const COMMON_LOGIN_COMP_PROPS = {
  type: 'LoginSocialBarProperties',
  buttonsDirection: 'ltr',
  iconSize: 26,
  dropDownTextAlignment: 'left',
  buttonsAlignment: 'right',
};
